import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, HostCard } from '../../components';
import config from '../../config';

import css from './HostsPage.module.css';

const SectionTitle = ({ listing }) => {
  if (!listing.id) return null;

  const { title = '', publicData } = listing.attributes;

  const listingType = publicData.type;
  const isOfShowListingType = listingType === config.listingTypes['show'];

  return (
    <h2 className={css.hostsTitle}>
      <FormattedMessage
        id="HostsPage.hostsHeading"
        values={{
          listingTitle: (
            <NamedLink
              name={isOfShowListingType ? 'ListingPage' : 'ExperiencePage'}
              params={{
                id: listing.id.uuid,
                slug: createSlug(title),
              }}
            >
              {title}
            </NamedLink>
          ),
        }}
      />
    </h2>
  );
};

const SectionStatus = ({ fetchUsersInProgress, users, fetchUsersError }) => {
  if (fetchUsersInProgress) {
    return (
      <div className={css.sectionHostsMaybe}>
        <p className={css.loadingText}>
          <FormattedMessage id="HostsPage.loadingHostsMessage" />
        </p>
      </div>
    );
  } else if (fetchUsersError) {
    return (
      <div className={css.sectionHostsMaybe}>
        <p className={css.errorText}>
          <FormattedMessage id="HostsPage.fetchHostsError" />
        </p>
      </div>
    );
  } else if (users.length === 0) {
    return (
      <div className={css.sectionHostsMaybe}>
        <p className={css.noHostsMessage}>
          <FormattedMessage id="HostsPage.noHostsMessage" />
        </p>
      </div>
    );
  }
  return null;
};

const UsersByRoles = ({ users, teamFromPublicData }) => {
  const roles = users.map(u => {
    const userData = teamFromPublicData?.find(i => i.id === u.id.uuid);
    return userData?.credit;
  });

  return (
    <div className={css.sectionHostsMaybe}>
      {roles.map(role => (
        <RoleSection key={role} role={role} users={users} teamFromPublicData={teamFromPublicData} />
      ))}
    </div>
  );
};

const RoleSection = ({ role, users, teamFromPublicData }) => {
  const usersByRole = users.filter(u => {
    const userData = teamFromPublicData?.find(i => i.id === u.id.uuid);
    return userData?.credit === role;
  });

  return (
    <div className={css.hostsByRoles}>
      <h3 className={css.roleHeading}>{role}</h3>
      <div className={css.hosts}>
        {usersByRole.map(user => (
          <HostCard key={user.id.uuid} user={user} teamFromPublicData={teamFromPublicData} />
        ))}
      </div>
    </div>
  );
};

const SectionHostsMaybe = props => {
  const { listing, users, fetchUsersInProgress, fetchUsersError, teamFromPublicData } = props;

  return (
    <>
      <SectionTitle listing={listing} />
      <SectionStatus
        fetchUsersInProgress={fetchUsersInProgress}
        users={users}
        fetchUsersError={fetchUsersError}
      />
      {!fetchUsersInProgress && !fetchUsersError && users.length > 0 && (
        <UsersByRoles users={users} teamFromPublicData={teamFromPublicData} />
      )}
    </>
  );
};

export default SectionHostsMaybe;
