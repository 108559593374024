import React from 'react';
import { array, bool, func, shape, string, number, oneOfType, arrayOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import { propTypes } from '../../util/types';
import { withViewport } from '../../util/contextHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureListing, ensureUser, userDisplayNameAsString } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  IconArrowHead,
  SectionRecentlyViewedListings,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';

// Sections
import SectionHostsMaybe from './SectionHostsMaybe';
import SectionSimilarListings from './SectionSimilarListings';

import css from './HostsPage.module.css';

const { UUID } = sdkTypes;

const preparesimilarListings = (listings, listingId) =>
  listings.filter(l => l.id.uuid !== listingId).slice(0, 4);

const HostsPageComponent = props => {
  const {
    intl,
    params: rawParams,
    // UI
    scrollingDisabled,
    // Listing
    getListing,
    showListingError,
    // user(s)
    users,
    fetchUsersInProgress,
    fetchUsersError,
    // listings
    similarListings,
    querySimilarListingsInProgress,
    querySimilarListingsError,
    recentlyViewedListings,
    queryRecentlyViewedListingsInProgress,
    queryRecentlyViewedListingsError,
  } = props;

  const listingId = new UUID(rawParams.id);
  const currentListing = ensureListing(getListing(listingId));

  const { description = '', title = '', publicData } = currentListing.attributes;

  const listingType = publicData?.type;
  const isListingOfShowType = listingType === config.listingTypes['show'];

  const topbar = (
    <TopbarContainer desktopClassName={css.topbarDesktop} contentClassName={css.topbarContent} />
  );

  const authorAvailable = currentListing && currentListing.author;
  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    const errorTitle = intl.formatMessage({
      id: 'HostsPage.errorLoadingListingTitle',
    });

    return (
      <Page title={errorTitle} scrollingDisabled={scrollingDisabled} currentPage="HostsPage">
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.mainErrorText}>
              <FormattedMessage id="HostsPage.errorLoadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer currentPage="HostsPage" />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  } else if (!currentListing.id) {
    // Still loading the listing

    const loadingTitle = intl.formatMessage({
      id: 'HostsPage.loadingListingTitle',
    });

    return (
      <Page title={loadingTitle} scrollingDisabled={scrollingDisabled} currentPage="HostsPage">
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.mainLoadingText}>
              <FormattedMessage id="HostsPage.loadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer currentPage="HostsPage" />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const listingImages = (listing, variantName) =>
    (listing.images || [])
      .map(image => {
        const variants = image.attributes.variants;
        const variant = variants ? variants[variantName] : null;

        // deprecated
        // for backwards combatility only
        const sizes = image.attributes.sizes;
        const size = sizes ? sizes.find(i => i.name === variantName) : null;

        return variant || size;
      })
      .filter(variant => variant != null);

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'HostsPage.schemaTitle' }, { title, siteTitle });

  const returnLink = (
    <NamedLink
      className={css.returnLink}
      name={isListingOfShowType ? 'ListingPage' : 'ExperiencePage'}
      params={{ id: listingId.uuid, slug: createSlug(title) }}
    >
      <IconArrowHead className={css.returnLinkIcon} direction="left" />
      <FormattedMessage id="HostsPage.returnText" />
    </NamedLink>
  );

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      currentPage="HostsPage"
      contentType="website"
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description: description,
        name: schemaTitle,
        image: schemaImages,
      }}
    >
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar className={css.pageTopbar}>{topbar}</LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div>
            <div className={css.contentContainer}>
              <div className={css.mainContent}>
                {returnLink}
                <SectionHostsMaybe
                  listing={currentListing}
                  users={users}
                  fetchUsersInProgress={fetchUsersInProgress}
                  fetchUsersError={fetchUsersError}
                  teamFromPublicData={publicData?.team}
                />
              </div>
              <div className={css.asideContent}>
                <SectionSimilarListings
                  similarListings={preparesimilarListings(similarListings, listingId.uuid)}
                  querySimilarListingsInProgress={querySimilarListingsInProgress}
                  querySimilarListingsError={querySimilarListingsError}
                />
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <SectionRecentlyViewedListings
            intl={intl}
            recentlyViewedListings={recentlyViewedListings}
            queryRecentlyViewedListingsInProgress={queryRecentlyViewedListingsInProgress}
            queryRecentlyViewedListingsError={queryRecentlyViewedListingsError}
          />
          <Footer currentPage="HostsPage" />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

HostsPageComponent.defaultProps = {
  // listing
  showListingInProgress: false,
  showListingError: null,

  // user(s)
  isAuthenticated: false,
  currentUser: null,
  hasUsers: false,
  fetchUsersInProgress: false,
  fetchUsersError: null,

  // listing(s)
  querySimilarListingsInProgress: false,
  querySimilarListingsError: null,
  recentlyViewedListings: [],
  queryRecentlyViewedListingsInProgress: false,
  queryRecentlyViewedListingsError: null,

  filterConfig: config.custom.filters,
};

HostsPageComponent.propTypes = {
  // UI
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,

  // listing
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  showListingError: propTypes.error,

  // user(s)
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  users: arrayOf(propTypes.user).isRequired,
  hasUsers: bool.isRequired,
  fetchUsersInProgress: bool.isRequired,
  fetchUsersError: propTypes.error,

  // listing(s)
  similarListings: arrayOf(propTypes.listing),
  querySimilarListingsInProgress: bool.isRequired,
  querySimilarListingsError: propTypes.error,
  recentlyViewedListings: arrayOf(propTypes.listing),
  queryRecentlyViewedListingsInProgress: bool.isRequired,
  queryRecentlyViewedListingsError: propTypes.error,

  params: shape({
    id: string.isRequired,
  }).isRequired,
  filterConfig: array,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    showListingInProgress,
    userIds,
    fetchUsersInProgress,
    fetchUsersError,
  } = state.HostsPage;
  const {
    similarListingIds,
    querySimilarListingsInProgress,
    querySimilarListingsError,
  } = state.HostsPage;
  const {
    currentUser,
    currentUserReactions,
    recentlyViewedListingIds,
    queryRecentlyViewedListingsInProgress,
    queryRecentlyViewedListingsError,
  } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const userEntities = userIds.map(userId => ({ type: 'user', id: userId }));
  const users = getMarketplaceEntities(state, userEntities);
  const hasUsers = users.length > 0;

  return {
    isAuthenticated,
    currentUser,
    currentUserReactions,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    showListingInProgress,
    showListingError,
    similarListings: similarListingIds.map(id => getListing(id)),
    querySimilarListingsInProgress,
    querySimilarListingsError,
    recentlyViewedListings: recentlyViewedListingIds.map(id => getListing(id)),
    queryRecentlyViewedListingsInProgress,
    queryRecentlyViewedListingsError,
    users,
    hasUsers,
    fetchUsersInProgress,
    fetchUsersError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const HostsPage = compose(
  withRouter,
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(HostsPageComponent);

export default HostsPage;
