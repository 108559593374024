import React from 'react';
import { findOptionsForSelectFilter } from '../../util/search';
import { ResponsiveImage, NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';

import css from './HostsPage.module.css';

const VerticalListingCard = props => {
  const { listing } = props;
  const { title, publicData } = listing.attributes;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  const listingType = publicData.type;
  const isListingOfShowType = listingType === config.listingTypes['show'];

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const category = categoryOptions.find(c => c.key === publicData.category);

  return (
    <NamedLink
      className={css.verticalListingCard}
      name={isListingOfShowType ? 'ListingPage' : 'ExperiencePage'}
      params={{ id: listing.id.uuid, slug: createSlug(title) }}
    >
      <ResponsiveImage
        rootClassName={css.verticalListingCardRootImage}
        alt={title}
        image={firstImage}
        variants={['scaled-small', 'scaled-medium']}
      />
      <div className={css.verticalListingCardContent}>
        <h3 className={css.verticalListingCardTitle}>{title}</h3>
        <div className={css.verticalListingCardSubTitle}>{category.label}</div>
      </div>
    </NamedLink>
  );
};

const SectionSimilarListings = props => {
  const { similarListings, querySimilarListingsInProgress, querySimilarListingsError } = props;

  const hasSimilarListings = similarListings.length > 0;
  const showSimilarListings =
    hasSimilarListings && !querySimilarListingsInProgress && !querySimilarListingsError;

  return showSimilarListings ? (
    <div className={css.sectionSimilarListings}>
      <h2 className={css.similarListingsHeading}>
        <FormattedMessage id="HostsPage.similarListings" />
      </h2>
      {similarListings.map(l => {
        return <VerticalListingCard key={l.id.uuid} listing={l} />;
      })}
    </div>
  ) : null;
};

export default SectionSimilarListings;
